import type { AlpineComponent } from 'alpinejs'
import Swiper from 'swiper'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import { SwiperOptions } from 'swiper/types'
import 'swiper/css/bundle'
import 'swiper/css/pagination'
import { merge } from 'ts-deepmerge'

// if better types get needed check
// https://github.com/alpinejs/alpine/issues/2199#issuecomment-1809892127

export function Slider(
  config: SwiperOptions,
  fractionPagination: boolean = false,
  pauseOnHover: boolean = false,
): AlpineComponent<{
  swiper: Swiper | undefined
  init(): void
  discard(): void,
  goToSlide(slideNum: number): void
}> {
  const defaultConfig: SwiperOptions = {
    modules: [Pagination, Navigation, Autoplay],
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    pagination: {
      el: this.$refs.pagination,
      type: 'bullets',
      clickable: true,
      bulletClass:
        'inline-block rounded-full w-1.5 h-1.5 ring-1 ring-gray-300 ring-offset-2 ring-offset-white cursor-pointer',
      bulletActiveClass: 'bg-gray-300',
    },
    navigation: {
      nextEl: this.$refs.next,
      prevEl: this.$refs.prev,
      disabledClass: 'opacity-50 cursor-not-allowed',
    },
  }
  let resizeObserver: null | ResizeObserver = null

  return {
    swiper: undefined,

    init() {
      this.swiper = new Swiper(this.$refs.slider, merge(defaultConfig, config))

      if (fractionPagination) {
        // let denominator = Math.ceil(
        //   this.swiper.slides.length / this.swiper.slidesPerViewDynamic(),
        // )

        let denominator = this.swiper.slides.length

        this.$refs.fraction.innerHTML = `<span class="font-bold">${Math.floor(this.swiper.slidesPerViewDynamic())}</span>/<span class="text-xs">${denominator}</span>`

        this.swiper.on('slideChange', () => {

          // changed this to align for slides per view and slides per group
          let slideVal = Math.floor(this.swiper.slidesPerViewDynamic()) * (this.swiper.snapIndex + 1);

          this.$refs.fraction.innerHTML = `<span class="font-bold">${slideVal}</span>/<span class="text-xs">${denominator}</span>`
        }) 
      }

      if (pauseOnHover) {
        this.$el.addEventListener('mouseenter', () => {
          // stop autoplay immediately, causes a jerk when started
          // do to swiper setting the next slide to start
          let translate = this.swiper?.getTranslate()
          this.swiper?.translateTo(translate, 1)
          this.swiper?.slideToClosest(100)
          this.swiper?.autoplay.stop()
        })

        this.$el.addEventListener('focusin', () => {
          let translate = this.swiper?.getTranslate()
          this.swiper?.translateTo(translate, 1)
          this.swiper?.slideToClosest(100)
          this.swiper?.autoplay.stop()
        })

        this.$el.addEventListener('mouseleave', () => {
          this.swiper?.autoplay.start()
        })
      }

      // use resize observer to update swiper on window resize
      resizeObserver = new ResizeObserver(() => {
        this.swiper && this.swiper.update()
      })
      resizeObserver.observe(this.$el)
    },

    discard() {
      this.swiper && this.swiper.destroy()
      this.swiper = undefined

      resizeObserver && resizeObserver.disconnect()
    },

    goToSlide(slideNum) {
      this.swiper?.slideTo(slideNum, 10, false)
    }
  }
}
