import gsap from 'gsap'
import { SplitText } from 'gsap/all'

export function nestedLinesSplit(target: gsap.DOMTarget, vars: SplitText.Vars) {
  target = gsap.utils.toArray(target)

  if (target.length > 1) {
    let splits = target.map((t: gsap.DOMTarget) => nestedLinesSplit(t, vars)),
      result = splits[0],
      resultRevert = result.revert

    result.lines = splits.reduce(
      (acc: [], cur: any) => acc.concat(cur.lines),
      [],
    )
    result.revert = () =>
      splits.forEach((s: any) => (s === result ? resultRevert() : s.revert()))
    return result
  }
  target = target[0]

  if (!target) return

  let contents = target.innerHTML

  gsap.utils.toArray(target.children).forEach((child) => {
    let split = new SplitText(child, { type: 'lines' })
    split.lines.forEach((line) => {
      let clone = child.cloneNode(false)
      clone.innerHTML = line.innerHTML
      target.insertBefore(clone, child)
    })

    target.removeChild(child)
  })

  let split = new SplitText(target, vars),
    originalRevert = split.revert

  split.revert = () => {
    originalRevert.call(split)
    target.innerHTML = contents
  }
  return split
}
