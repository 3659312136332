import 'what-input'
import AsyncAlpine from 'async-alpine'
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import alpineDropdown from './components/alpineDropdown.js'
import { Slider } from './components/slider'
import { RowSlider } from './components/rowSlider'

// animations
import '@js/animations/animations.ts'

// Video facades
import '@js/components/liteYoutube.ts'
import '@js/components/liteVimeo.ts'

window.Alpine = Alpine
AsyncAlpine.init(Alpine)

Alpine.plugin(intersect)
Alpine.plugin(focus)
Alpine.plugin(collapse)

Alpine.data('slider', Slider)
Alpine.data('rowSlider', RowSlider)

AsyncAlpine.data('masonryLayout', () => import('./components/masonry'))
AsyncAlpine.data('scrollingGrid', () => import('./components/scrollingGrid'))
AsyncAlpine.data('dualSlider', () => import('./components/dualSlider'))
AsyncAlpine.data('timelineSlider', () => import('./components/timelineSlider'))

alpineDropdown(Alpine)

AsyncAlpine.start()
Alpine.start()
