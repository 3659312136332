import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/all'
import { nestedLinesSplit } from '../lib/utils'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

let mm = gsap.matchMedia()

const heroImg = document.querySelector('.hero-img')
const heroCopy = document.querySelector('.hero-copy')
const heroLine = document.querySelector('.hero-line')

if (heroImg) {
  mm.add('(prefers-reduced-motion: no-preference)', () => {
    ScrollTrigger.create({
      trigger: heroImg,
      pin: true,
      pinSpacing: false,
      start: 'top',
      end: 'bottom',
    })

    gsap.to('.hero-img img', {
      ease: 'power.in',
      scale: 1.1,
      scrollTrigger: {
        trigger: '.hero-img img',
        start: 'top',
        end: 'bottom',
        scrub: true,
      },
    })

    if (heroCopy) {
      gsap.to(heroCopy, {
        ease: 'power2.out',
        y: -100,
        scrollTrigger: {
          trigger: heroImg.parentElement,
          start: 'top',
          end: 'bottom',
          scrub: true,
        },
      })
    }

    if (heroLine) {
      gsap.to('.hero-line', {
        height: '100%',
        scrollTrigger: {
          trigger: heroImg.parentElement,
          start: 'top',
          end: '80%',
          scrub: true,
        },
      })
    }
  })
}

let heroLinks = document.querySelectorAll('.hero-link')
heroLinks.forEach((link) => {
  mm.add('(prefers-reduced-motion: no-preference)', () => {
    const figure = link.querySelector('figure')
    const img = link.querySelector('img')
    const title = link.querySelector('h3')
    const copy = link.querySelector('p')

    function setupAnimation() {
      const figureRect = figure?.getBoundingClientRect()
      const titleRect = title?.getBoundingClientRect()

      if (!figureRect || !titleRect) return

      const figureHeight = figureRect.height
      const titleHeight = titleRect.height
      const titleInitialY = titleRect.top - figureRect.top

      const tl = gsap.timeline({ paused: true })

      tl.addLabel('start', 0)

      tl.to(
        figure,
        {
          duration: 0.3,
          width: '100%',
          ease: 'power2.out',
        },
        'start',
      )

      tl.to(
        img,
        {
          duration: 0.3,
          opacity: 0.5,
          ease: 'power2.out',
        },
        'start',
      )

      const targetY = (figureHeight - titleHeight) / 2 - titleInitialY

      tl.to(
        title,
        {
          duration: 0.3,
          y: targetY,
          x: 16,
          ease: 'power2.out',
        },
        'start',
      )

      tl.fromTo(
        copy,
        {
          opacity: 1,
        },
        {
          duration: 0.3,
          opacity: 0,
          ease: 'power2.out',
        },
        'start',
      )

      link.addEventListener('mouseenter', () => tl.play())
      link.addEventListener('mouseleave', () => tl.reverse())
    }

    if (img?.complete) {
      setupAnimation()
    } else {
      img?.addEventListener('load', setupAnimation)
    }
  })
})

// ---------------------------

const sections: HTMLElement[] = gsap.utils.toArray(
  document.querySelectorAll('.scoll-sticky-item'),
)

sections.forEach((section) => {
  mm.add('(min-width: 768px)', () => {
    ScrollTrigger.create({
      trigger: section,
      start: () =>
        section.offsetHeight < window.innerHeight ? 'top top' : 'bottom bottom', // if it's shorter than the viewport, we prefer to pin it at the top
      pin: true,
      pinSpacing: false,
    })
  })
})

// ---------------------------

const lineAnimationEls: HTMLElement[] = gsap.utils.toArray(
  document.querySelectorAll('.lines-fade-up p'),
)

lineAnimationEls.forEach((el) => {
  // get the most nested element that contains the lines
  const split = new nestedLinesSplit(el, {
    type: 'lines',
  })

  split.lines.forEach((line) => {
    gsap.fromTo(
      line,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: line,
          start: 'top 80%',
          end: 'top 60%',
          // scrub: true, // Smooth scrubbing effect
          // markers: true, // For debugging, remove in production
        },
      },
    )
  })
})
